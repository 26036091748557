import { Modal } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  ModalContainer,
  ModalContent,
  ModalCustomHeader,
  ModalTitle,
} from "./ImagePickerModal.styled";
import Tabs2 from "../../../Tabs2/Tabs2";
import CloseIcon from "@mui/icons-material/Close";
import { useStore } from "../../../../../hooks";
import { FiltersTab, ImagesGrid, Paginations, UploadTab } from "./components";
import useFilters from "./useFilters";

const tabsCustomLibrary = [
  {
    label: "Upload",
    permission: null,
  },
  {
    label: "Vehicle Stock",
    permission: null,
  },
];

const MediaLibraryTab = {
  Upload: 0,
  VehicleStock: 1,
};

const ImagePickerModal = (props) => {
  const {
    doneCallbackRef,
    isOpenCustomLibraryModal,
    setIsOpenCustomLibraryModal,
  } = props;
  const { filters, setters } = useFilters();
  const { templateEditorStore } = useStore();

  const [isLoading, setIsLoading] = useState(false);
  const [filteredCars, setFilteredCars] = useState([]);
  const [totalRowCount, setTotalRowCount] = useState({
    count: 0,
    currentPage: 0,
    pageSize: 0,
  });

  const [responsePageSize, setResponsePageSize] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCurrentPage, setSelectedCurrentPage] = useState(1);
  const totalPages = Math.ceil(responsePageSize / 3);

  const getVisiblePages = useCallback(() => {
    const start = (currentPage - 1) * 3 + 1;
    const end = Math.min(start + 2, responsePageSize);
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  }, [currentPage, responsePageSize]);

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  const handlePrev = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText, setDebouncedSearchText] = useState("");
  const [entries, setEntries] = useState("10");
  const [currentTab, setCurrentTab] = useState(MediaLibraryTab.Upload);
  const debounceTimeoutRef = useRef(null);

  const handleTextSearch = (e) => {
    const text = e.target.value;
    setSearchText(text);

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      setDebouncedSearchText(text);
    }, 1000);
  };

  const visiblePages = getVisiblePages();

  const getImageStock = async () => {
    if (!filters || !setters) return;

    setIsLoading(true);

    const filtersPayload = {
      make: filters.selectedMake,
      model: filters.selectedModel,
      year: filters.selectedYear,
      type: filters.selectedType,
      angle: filters.selectedAngle,
      background: filters.selectedBackground,
      size: filters.selectedSize,
    };

    const filtersWithValues = Object.fromEntries(
      Object.entries(filtersPayload).filter(
        ([key, value]) => value && value.length > 0
      )
    );

    const parameters = {
      page_size: entries,
      page: selectedCurrentPage,
      order_by: {
        id: "1",
        desc: true,
      },
      search: debouncedSearchText,
      filters: filtersWithValues,
    };

    const response = await templateEditorStore.getVehiclesStockPicturesRequest(
      parameters
    );

    const filtersResponse =
      await templateEditorStore.getVehiclesStockPicturesFilters();

    setters.setFiltersData.setMake(filtersResponse?.make);
    setters.setFiltersData.setModels(filtersResponse?.model);
    setters.setFiltersData.setYears(filtersResponse?.year);
    setters.setFiltersData.setTypes(filtersResponse?.type);
    setters.setFiltersData.setAngles(filtersResponse?.angle);
    setters.setFiltersData.setBackgrounds(filtersResponse?.background);
    setters.setFiltersData.setSizes(filtersResponse?.size);

    setFilteredCars(response?.vehicles);
    setTotalRowCount(response?.totalRowCount);
    setResponsePageSize(response?.pageSize);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!filters || !setters) return;
    setCurrentPage(1);
    setSelectedCurrentPage(1);
    getImageStock();
  }, [
    filters.selectedMake,
    filters.selectedModel,
    filters.selectedYear,
    filters.selectedType,
    filters.selectedAngle,
    filters.selectedBackground,
    filters.selectedSize,
    entries,
    debouncedSearchText,
  ]);

  const onHandleCloseModal = () => {
    setIsOpenCustomLibraryModal(false);
    handleClearAllFilters();
  };

  const handleFile = (file) => {
    doneCallbackRef.current({
      url: file,
    });
    setIsOpenCustomLibraryModal(false);
  };

  const selectPage = (page) => {
    setSelectedCurrentPage(page);
    getImageStock();
  };

  const handleClearAllFilters = () => {
    setters.setSize([]);
    setters.setBackground([]);
    setters.setAngle([]);
    setters.setType([]);
    setters.setMake([]);
    setters.setModel([]);
    setters.setYear([]);
    setSearchText("");
    setDebouncedSearchText("");

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    getImageStock();
  };

  return (
    <Modal
      open={isOpenCustomLibraryModal}
      onClose={onHandleCloseModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      sx={{ zIndex: 3001 }}
    >
      <ModalContainer>
        <ModalContent>
          <ModalCustomHeader>
            <ModalTitle>
              <p>My media library</p>
              <Tabs2
                defaultTab={currentTab}
                tabs={tabsCustomLibrary}
                onToggleTab={(tab) => setCurrentTab(tab)}
              />
            </ModalTitle>
            <CloseIcon fontSize="small" onClick={onHandleCloseModal} />
          </ModalCustomHeader>

          <Tabs2.Outlet show={currentTab === MediaLibraryTab.Upload}>
            <UploadTab handleFile={handleFile} />
          </Tabs2.Outlet>
          <Tabs2.Outlet show={currentTab === MediaLibraryTab.VehicleStock}>
            <FiltersTab
              filters={filters}
              setters={setters}
              isLoading={isLoading}
              handleTextSearchDebounced={handleTextSearch}
              handleClearAllFilters={handleClearAllFilters}
              searchText={searchText}
            />
            <ImagesGrid
              images={filteredCars}
              isLoading={isLoading}
              onClickImage={handleFile}
            />
            <Paginations
              entries={entries}
              setEntries={setEntries}
              totalRowCount={totalRowCount}
              visiblePages={visiblePages}
              currentPage={currentPage}
              totalPages={totalPages}
              handlePrev={handlePrev}
              selectPage={selectPage}
              handleNext={handleNext}
              selectedCurrentPage={selectedCurrentPage}
              isLoading={isLoading}
            />
          </Tabs2.Outlet>
        </ModalContent>
      </ModalContainer>
    </Modal>
  );
};

export default ImagePickerModal;
