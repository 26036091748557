import styled, { css } from "styled-components";

export const ModalContainer = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
`

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 70%;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    height: 600px;

    & p {
        font-weight: bold;
    }
`

export const ModalCustomHeader = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        cursor: pointer;
    }
`

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 3000;

  ${(props) =>
    props.isOpen &&
    css`
      display: flex;
    `}
`;

export const TemplateEditorWrapper = styled.section`
  background-color: ${(props) => props.theme.colors.white};
  height: calc(100% - 40px);
  display: flex;
  width: 85%;
  flex-direction: column;
  justify-content: space-between;
`;

export const TemplateEditorContainer = styled.div`
  min-height: 90%;
  flex: 1;
  display: flex;
`;

export const TemplateEditorFooter = styled.div`
  display: flex;
  height: 10%;
  max-height: 72px;
  border: 1px solid ${(props) => props.theme.colors.clearGray};
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
  padding: 0 16px;
`;

export const TabContainer = styled.div`
  margin: 24px;
  display: flex;
  flex-direction: column;

  h1 {
    margin: 0;
  }
`;

export const TabsWrapper = styled.div`
  flex: 2;
  min-width: 420px;
`;

export const EmailEditorWrapper = styled.div`
  flex: 5;

  > div {
    height: 100%;
    min-width: 0;
  }

  iframe {
    /* Intended to use important since the library forces the width to be a minimum of 1024px */
    min-width: 100% !important;
  }
`;

export const Subtitle = styled.p`
  ${(props) => props.theme.fonts.variant.semiBold()};
  font-size: 1em;
`;

export const InputWrapper = styled.span`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 20px;

  p {
    margin: 0;
  }

  > div {
    margin: 0;
  }
`;

export const ButtonWrapper = styled.div`
  float: right;
  button {
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.clearBlue};
    font-weight: 400;
  }
`;

export const VariableContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 90%;

  input {
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.darkGray};
    font-size: 0.875em;
    padding: 0 5px;
  }
`;

export const VariableHeader = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.colors.highClearGray};
  height: 48px;
  align-items: center;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom: 1px solid ${(props) => props.theme.colors.clearGray};

  span,
  div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div:nth-child(1) {
    flex: 1;
  }

  div:nth-child(2) {
    flex: 1;
    border: 1px solid ${(props) => props.theme.colors.clearGray};
    border-top: 0;
    border-bottom: 0;
  }

  span:nth-child(3) {
    margin: 10px;
  }

  div {
    input {
      height: 32px;
    }
  }
`;

export const VariableBody = styled.div`
  background-color: ${(props) => props.theme.colors.primaryWhite};
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  height: 122px;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > div:first-child {
    margin-top: 10px;
    > div:first-child {
      border: 1px solid ${(props) => props.theme.colors.clearGray};
    }
  }

  > div {
    > div {
      border: 1px solid ${(props) => props.theme.colors.clearGray};
    }
  }

  input {
    width: 90%;
    height: 40px;
  }
`;

export const VariableWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
`;

export const ButtonContiner = styled.div`
  height: 36px;
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const VariableSelects = styled.div`
  margin-top: 10px;
  display: flex;
  gap: 5px;
`;
