import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { DefaultTheme } from "../../../../../theme";
import { useRef } from "react";

const ITEM_HEIGHT = 48;

const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      marginTop: 5,
      marginLeft: 55,
      width: 230,
      zIndex: 3000,
    },
    sx: {
      border: `1px solid ${DefaultTheme.colors.silverGrayTwo}`,
      boxShadow: `0px 4px 4px 0px #00000040`,
    },
  },
};

const DefaultFormControlStyle = { m: 1, width: 120, height: 48 };

const Filters = (props) => {
  const { value, setValue, data, name, onClose, disabled } = props;
  const selectRef = useRef(null);

  const outlinedStyle = {
    height: "100%",
    borderRadius: "6px",
    backgroundColor: DefaultTheme.colors.silverGrayThree,
    borderColor: DefaultTheme.colors.silverGrayTwo,
  };

  return (
    <FormControl sx={DefaultFormControlStyle}>
      <InputLabel
        sx={{
          fontWeight: "bold",
          fontSize: "13px",
          color: DefaultTheme.colors.blackTwo,
        }}
      >
        {name}
      </InputLabel>
      <Select
        ref={selectRef}
        disabled={disabled}
        multiple
        value={value}
        onChange={setValue}
        input={<OutlinedInput label={name} sx={outlinedStyle} />}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={MenuProps}
        InputLabelProps={{
          sx: {
            fontWeight: "bold",
            fontSize: "14px",
          },
        }}
      >
        {data?.map((name) => (
          <MenuItem
            key={name}
            value={name}
            disabled={disabled}
            sx={{
              borderBottom: `1px solid ${DefaultTheme.colors.cerebralGray}`,
              marginInline: 1,
              "&:last-child": {
                borderBottom: "none",
              },
            }}
          >
            <ListItemText
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "180px",
              }}
              primary={name}
            />
            <Checkbox checked={value.includes(name)} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Filters;
